<template>
  <button class="icon-butt" @click.stop="handleClick" @touchstart.stop="handleTouchStart">
    <div class="ripple" v-ripple="{ color: 'gray' }"></div>
    <slot />
  </button>
</template>

<script setup lang="ts">
const emit = defineEmits(['click', 'touchstart'])

const handleClick = () => {
  emit('click')
}
const handleTouchStart = () => {
  emit('touchstart')
}
</script>

<style scoped lang="scss">
.ripple {
  display: none;
  width: 110%;
  height: 110%;
  border-radius: 12px;
  position: absolute;
  top: 0;
}
.icon-butt {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  transition: transform 0.3s ease;
  transform-origin: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
  &:active {
    transform: scale(0.9);
  }
}
@media screen and (min-width: 200px) and (max-width: 1279px) {
  .ripple {
    display: block;
  }
  .icon-butt {
    &:hover {
      transform: none;
    }
    &:active {
      transform: none;
    }
  }
}
</style>
